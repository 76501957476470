import React, { useRef, useMemo, ReactElement, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import useTranslation from '../../hooks/useTranslation';
import useBase64Formatter from './hooks/useBase64Formatter';
import styles from './PhotoInstructions.module.scss';
import pageStyles from '../scss/Pages.module.scss';
import useImageUploadInput from './hooks/useImageInputUpload';
import useVideoMediaStream from './hooks/useVideoMediaStream';
import useDesktopNavigationContext from '../../context/desktopNavigationContext';
import convertStringToBool from '../../helpers/convertStringToBool';
import usePageContext from '../../context/pageContext';

import Button from '../../components/_ui/Button/Button';
import PanelAnimation from '../../components/Panels/PanelAnimation/PanelAnimation';
import TakePhoto from '../../components/Panels/TakePhoto/TakePhoto';
import PageNavigation from '../../components/_ui/PageNavigation/PageNavigation';
import useGlobalContext from '../../context/globalContext';

/**
 * The page containing the photo instructions and upload image logic.
 * @returns {ReactElement}
 */
const PhotoInstructions = (): ReactElement => {
    // If the upload request has errored before.
    const [fileTypeError, setFileTypeError] = useState(false);
    const showFileTypeError = () => setFileTypeError(true);

    // Whether or not to show the take photo panel.
    const [showTakePhoto, setShowTakePhoto] = useState(false);
    const toggleCameraPanel = () => setShowTakePhoto((state) => !state);

    // Get the translation function.
    const { translate, exists } = useTranslation();

    // Get the previous page function.
    const { onPrevPage } = usePageContext();

    const { uploadError } = useGlobalContext();

    // Get desktop functions to add close and back functionality.
    const { addNavigationFunctions } = useDesktopNavigationContext();

    // Check to see if the device has a camera and it is accessable.
    const { mediaStream } = useVideoMediaStream();
    const isCameraAvailable = mediaStream !== null;

    // Create a reference for the hidden upload field.
    const uploadRef = useRef<HTMLInputElement>(null);

    // Get the image upload functions and states from the hook.
    const { handleFormatToBase64 } = useBase64Formatter();

    // Handles interaction with the file input.
    const { handleUploadClick, handleFileUpload } = useImageUploadInput(uploadRef, handleFormatToBase64, showFileTypeError);

    // Displays the close button for the take photo panel on desktop.
    useEffect(() => {
        addNavigationFunctions(onPrevPage, showTakePhoto ? toggleCameraPanel : null);
    }, [showTakePhoto]);

    // console.log(uploadError); // null | 'UPLOAD_ERR' | 'API_ERR';

    const uploadMessage = useMemo(() => {
        switch (uploadError) {
        case 'UPLOAD_ERR':
            return translate('photo_instructions.face_not_found_error');

        case 'API_ERR':
            return translate('photo_instructions.api_key_depleted_error');

        default:
            return translate('photo_instructions.body_text');
        }
    }, [uploadError]);

    return (
        <main className={`${pageStyles.page} ${pageStyles.pagePadded} flex overflow-auto`}>
            <Helmet>
                <link rel="preload" href="/images/error-question-mark.svg" as="image" />
            </Helmet>
            <div className="container-xsmall my-auto">
                <PageNavigation onBack={onPrevPage} showLogo={false} />
                <div className="mt-8">
                    {uploadError ? (
                        <img
                            className={styles.errorImage}
                            src="/images/error-question-mark.svg"
                            alt={translate('global.images.face_with_question_mark')}
                        />
                    ) : (
                        <img
                            className={styles.examplePhoto}
                            src="/images/example-photo.jpeg"
                            alt={translate('global.images.example_portrait')}
                        />
                    )}
                </div>
                <section className={styles.description}>
                    { exists('photo_instructions.title') && !uploadError
                    && (
                        <h1 className="text-heading">{translate('photo_instructions.title')}</h1>
                    )}
                    { exists('photo_instructions.face_not_found_title') && uploadError === 'UPLOAD_ERR'
                    && (
                        <h1 className="text-heading">{translate('photo_instructions.face_not_found_title')}</h1>
                    )}
                    { exists('photo_instructions.api_key_depleted_title') && uploadError === 'API_ERR'
                    && (
                        <h1 className="text-heading">{translate('photo_instructions.api_key_depleted_title')}</h1>
                    )}
                    <p className="text-subheading mt-4">
                        {uploadMessage}
                    </p>
                    {fileTypeError ? (
                        <div className="mt-4 text-note text-error">
                            Please provide an image with a valid file type.
                        </div>
                    ) : null}
                    <div className="flex gap-3 mt-6">
                        <input
                            ref={uploadRef}
                            onChange={handleFileUpload}
                            name="user_photo"
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            hidden
                        />
                        {!convertStringToBool(process.env.REACT_APP_DISABLE_PHOTO_UPLOAD)
                            ? (
                                <Button onClick={handleUploadClick} className="w-full">
                                    {translate('photo_instructions.buttons.upload_photo')}
                                </Button>
                            ) : null}
                        <Button onClick={toggleCameraPanel} className="w-full" disabled={!isCameraAvailable}>
                            {translate('photo_instructions.buttons.take_photo')}
                        </Button>

                    </div>
                </section>
            </div>
            <PanelAnimation showPanel={showTakePhoto}>
                <TakePhoto
                    onPhotoTaken={handleFormatToBase64}
                    handleClose={toggleCameraPanel}
                />
            </PanelAnimation>
        </main>
    );
};

export default PhotoInstructions;
